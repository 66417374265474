import { InputBase } from "@mui/material"

export const MyInput = ({ value, setValue,required,inputRef }) => {

    

    return (
        <InputBase
            value={value}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            inputRef={inputRef} // Asigna la referencia al input
            sx={{ ml: 1, flex: 1 }}
            placeholder="Número de serie"
            inputProps={{ 'aria-label': 'Serial Number' }}
            required = {required}
        />
    );
};

export default MyInput;