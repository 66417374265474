import { Box, IconButton, Paper } from "@mui/material";
import MyInput from "./MyInput";
import { Colors } from "../utils/Colors";
import { Close, Search } from "@mui/icons-material";
import { useRef } from "react";

export const SearchBox = ({serie, setSerie, setSearched, handleSubmit}) => {
    const inputRef = useRef(null);

    const handleRequestFocus = () => {
        inputRef.current.focus();
      };
    return (
        <Paper elevation={3} sx={{ width: "100%" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              display="flex"
              sx={{
                alignItems: "center", // Alinea los elementos hijos verticalmente al centro
                justifyContent: "space-around", // Centra horizontalmente el contenido dentro del Box
                gap: ".5rem",
                padding: "0.2rem 0.5rem",
              }}
            >
              <MyInput
                inputRef={inputRef}
                value={serie}
                setValue={setSerie}
                required={true}
              />

              {serie !== "" && (
                <IconButton
                  onClick={(e) => {
                    setSerie("");
                    handleRequestFocus();
                    setSearched(false);
                  }}
                  sx={{
                    flexShrink: 0,
                    color: Colors.primary, // Color rojo personalizado
                    "&:hover": {
                      backgroundColor: Colors.primaryBack, // Cambia el color de fondo al pasar el mouse
                    },
                  }}
                >
                  <Close />
                </IconButton>
              )}

              <IconButton
                type="submit"
                sx={{
                  flexShrink: 0,
                  color: Colors.primary,
                  "&:hover": {
                    backgroundColor: Colors.primaryBack, // Cambia el color de fondo al pasar el mouse
                  },
                }}
              >
                <Search />
              </IconButton>
            </Box>
          </Paper>
    )
}