import { AdminPanelSettings, Download, Logout, UploadFile, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { Colors } from "../utils/Colors";
import { handleDownloadExcel, setUrl } from "../utils/common";
import { useEffect, useState } from "react";
import { EditPassword } from "./EditPassword";

export const Header = ({ user, navigate, data, showHistory, setShowHistory, setShowUploadExcel }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const open = Boolean(anchorEl);
    const userNameList = user && user.usuario ? user.usuario.split(" ") : ["Unknown"]
    const userName = userNameList[0]

    useEffect(() => {
        fetch(setUrl(`usuarios/${user.id}`))
            .then((response) => response.json())
            .then(data => setOldPassword(data[0].password))
            .catch((error) => console.error(error))
    }, [user])

    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        navigate("/")
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0 1rem"
            sx={{
                width: "100%",
                background: Colors.primary,
                position: "fixed",
                top: 0,
                right: 0,
                zIndex: 3
            }}
        >
            <Typography sx={{ color: "white" }} onClick={handleClick}>{userName}</Typography>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        setOpenModal(true)
                        setAnchorEl(null)
                    }}
                >
                    Cambiar contraseña
                </MenuItem>

            </Menu>

            <EditPassword open={openModal} setOpenEdit={setOpenModal} userId={user.id} oldPassword={oldPassword} />

            <Box
                display="flex"
                gap=".5rem"
            >
                <IconButton
                    title="subir excel"
                    onClick={(e) => setShowUploadExcel(true)}
                    sx={{
                        color: "white"
                    }}
                >
                    <UploadFile />
                </IconButton>

                {
                    user.isadmin === 1 && (
                        <IconButton
                            title="Vista de administrador"
                            onClick={(e) => navigate("/admin")}
                            sx={{
                                color: "white"
                            }}
                        >
                            <AdminPanelSettings />
                        </IconButton>
                    )
                }


                {
                    data.length > 0 && (
                        <>
                            <IconButton
                                title="Mostrar historial"
                                onClick={(e) => setShowHistory(!showHistory)}
                                sx={{
                                    color: "white"
                                }}
                            >
                                {
                                    showHistory ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )
                                }
                            </IconButton>

                            <IconButton
                                title="Descargar Excel"
                                onClick={(e) => handleDownloadExcel(data.map((dato) => ({ ...dato, responsable: user.usuario })))}
                                sx={{
                                    color: "white"
                                }}
                            >
                                <Download />
                            </IconButton>
                        </>
                    )
                }


                <IconButton
                    title="Cerrar Sesion"
                    onClick={(e) => handleLogout()}
                    sx={{
                        color: "white"
                    }}
                >
                    <Logout />
                </IconButton>
            </Box>

        </Box>
    )
}