import { Box, Typography } from "@mui/material"
import { Colors } from "../utils/Colors"

export const Footer = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding=".3rem"
            sx={{
                width: "100%",
                background: Colors.primary,
                position: "fixed",
                bottom: 0,
                right: 0,
            }}
        >
            <Typography variant="subtitle2" sx={{ color: "white" }}>Developed by Santos Rojas</Typography>
        </Box>
    )
}