import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import MyForm from "./MyForm";
import { formatDate, setUrl } from "../utils/common";
import { Delete, Edit } from "@mui/icons-material";

export const PumpInfo = ({ data, instituteOptions, serviceOptions, usuarios, setSearched, handleSubmit, obtenerInventarios, setServiceOptions }) => {
    const [showForm, setShowForm] = useState(false)
    const [messageDelete, setMessageDelete] = useState("")
    const responsable = usuarios.find(user => user.id === data.responsable_id);
    const responsableName = responsable ? responsable.usuario : ""
    const newData = { ...data, id: "", fecha_actualizacion: formatDate(data.fecha_actualizacion) !== "30-11-1899" ? formatDate(data.fecha_actualizacion, true) : "", responsable: responsableName, responsable_id: "" };
    const nonEmptyProperties = Object.keys(newData).filter(key => newData[key] !== '' && key !== "agregado");
    const responsableId = data.responsable_id
    const handleDeleteItem = () => {
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // Agrega otros encabezados si es necesario, como autenticación
                'Authorization': 'Bearer YOUR_TOKEN_HERE'
            }
        };
        // Enviar la solicitud DELETE usando fetch
        fetch(setUrl(`inventario/${data.id}`), options)
            .then(response => {
                if (!response.ok) {
                    setMessageDelete('Error en la solicitud: ' + response.statusText)
                    throw new Error('Error en la solicitud: ' + response.statusText);
                }
                return response.json(); // Si el servidor responde con un JSON
            })
            .then(data => {
                console.log('Elemento eliminado exitosamente:', data);
                setSearched(false)
                obtenerInventarios(responsableId)
            })
            .catch(error => {
                console.error('Hubo un problema con la solicitud:', error);
                setMessageDelete(error)
            });
    }

    return (
        !showForm ? (
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    alignItems: "flex-start", // Alinea los elementos hijos verticalmente al centro
                    justifyContent: "center",
                    gap: ".5rem",
                    padding: "1rem",
                }}
            >
                {nonEmptyProperties.map((property, index) => (
                    <Typography key={index} >
                        <strong>{property.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}:</strong> {newData[property]}
                    </Typography>
                ))}

                <Box
                    display="flex"
                    justifyContent={data.agregado > 0 ? "space-between" : "end"}
                    alignItems="center"
                    sx={{
                        width: "100%"
                    }}
                >
                    {
                        data.agregado > 0 && (
                            <Button onClick={(e) => handleDeleteItem()} variant="contained" color="error" startIcon={<Delete />}>
                                Eliminar
                            </Button>
                        )
                    }
                    <Button onClick={(e) => setShowForm(true)} variant="contained" color="primary" startIcon={<Edit />}>
                        Editar
                    </Button>
                </Box>
                {
                    messageDelete !== "" && (
                        <Typography>{messageDelete}</Typography>
                    )
                }

            </Box>
        ) : (
            <>
                <MyForm
                    data={data}
                    serviceOptions={serviceOptions}
                    setServiceOptions={setServiceOptions}
                    instituteOptions={instituteOptions}
                    setSearched={setSearched}
                    setShowForm1={setShowForm}
                    handleSubmit={handleSubmit}
                    obtenerInventarios={obtenerInventarios}
                />

            </>

        )
    );
};
