import { setUrl } from "../utils/common";

export const FetchData = async (serie) => {

    try {
        const response = await fetch(setUrl(`inventario/serie/${serie}`));
        // Verificamos si la respuesta es exitosa (código de estado HTTP 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        // Capturamos cualquier error de red o de parseo JSON
        console.error('Error fetching data:', error);
        return null;
    }
}