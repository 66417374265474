import { Box, IconButton, Typography } from "@mui/material"
import { Colors } from "../utils/Colors"
import { useMemo, useState } from "react"
import { handleDownloadExcel, organizarDatosPorCategoria, sortObjectByArrayLength } from "../utils/common"
import { Download, ExpandLess, ExpandMore } from "@mui/icons-material"
import { Progreso } from "./Avance"
import { EditCountPump } from "./EditCountPump"

export const DetailsByUser = ({ inventarios, responsable, countPump }) => {
    const [showDetails, setDetails] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [newCountPump, setNewCountPump] = useState(countPump)
    const userId = inventarios[0].responsable_id

    let porcentaje = 100
    if (countPump > 0) {
        porcentaje = inventarios.length * 100 / countPump
    }

    const dataSortedByInstitucion = sortObjectByArrayLength(organizarDatosPorCategoria(inventarios, "institucion"))

    const institucionsList = Object.keys(dataSortedByInstitucion)

    const getTypePump = (inventario) => {
        const inventarioList = inventario.modelo.split(" ")
        let value

        if (inventarioList.length > 1) {
            value = inventario.tipo.charAt(0) + inventarioList[0].charAt(0) + inventarioList[1].charAt(0)

        } else {
            value = inventario.tipo.charAt(0) + inventarioList[0].charAt(0)
        }


        return value
    }

    const totalByTipo = useMemo(() => {
        const newsInventarios = inventarios.map(inv => ({ ...inv, tipo_abreviado: getTypePump(inv) }))
        const ninvBynt = organizarDatosPorCategoria(newsInventarios, "tipo_abreviado")
        const result = Object.keys(ninvBynt)
            .map(key => `${ninvBynt[key].length} ${key}`)
            .join(' - ');

        return result

    }, [inventarios])


    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: "100%",
                gap: '.5rem',
                padding: '0rem',
                borderRadius: '4px',
                boxSizing: 'border-box',
                border: '2px solid ' + Colors.primary,
            }}
        >
            <Box
                display="flex"
                gap="1rem"
                sx={{
                    width: "100%",
                    padding: "0.3rem 0.5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: Colors.primaryBack,
                    borderRadius: showDetails ? "4px 4px 0 0" : "4px",
                    borderBottom: showDetails ? `2px solid ${Colors.primary}` : "none"
                }}

            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap=".2rem"
                    sx={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start"
                    }}
                >
                    <Typography variant="subtitle2">{responsable}</Typography>

                    <Box
                        display="flex"
                        gap="1rem"
                        sx={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between"

                        }}

                    >
                        <Progreso avance={porcentaje} />
                        <Typography
                        title = "Total de bombas"
                            sx={{
                                cursor: "pointer"
                            }}
                            variant="subtitle2"
                            onClick={(e) => setOpenEdit(true)}
                        >
                            <strong>({newCountPump})</strong>
                        </Typography>

                    </Box>
                </Box>
                <IconButton
                    title={
                        !showDetails ? (
                            "Ver mas"
                        ) : (
                            "Ver menos"
                        )
                    }

                    onClick={(e) => setDetails(!showDetails)}
                    sx={{
                        color: Colors.primary
                    }}
                >
                    {
                        !showDetails ? (
                            <ExpandMore />
                        ) : (
                            <ExpandLess />
                        )
                    }

                </IconButton>
            </Box>
            {
                showDetails && (
                    <>
                        <Box
                            display="flex"
                            padding="0.2rem .5rem"
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc"
                            }}
                        >
                            <Typography variant="subtitle2"><strong>Total:</strong> {inventarios.length} </Typography>
                            <IconButton
                                title="Descargar Excel"
                                onClick={(e) => handleDownloadExcel(inventarios.map((dato) => ({ ...dato, responsable: responsable })), responsable)}
                                sx={{
                                    color: Colors.primary
                                }}
                            >
                                <Download />
                            </IconButton>
                        </Box>
                        {
                            institucionsList.map((institucion, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    padding="0.2rem .5rem"
                                    gap="1rem"
                                    sx={{
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: index !== institucionsList.length - 1 ? "1px solid #ccc" : `2px solid ${Colors.primary}`
                                    }}
                                >
                                    <Typography variant="subtitle2">{institucion}</Typography>
                                    <Typography variant="subtitle2">{dataSortedByInstitucion[institucion].length}</Typography>

                                </Box>
                            ))
                        }

                        <Box display="flex" justifyContent="space-between" padding="0.2rem .5rem">
                            <Typography variant="subtitle2"><strong>Total: </strong> </Typography>

                            <Typography variant="subtitle2"><strong>{totalByTipo}</strong> </Typography>
                        </Box>
                    </>
                )
            }

            <EditCountPump open={openEdit} setOpenEdit={setOpenEdit} userId={userId} newCountPump={newCountPump} setNewCountPump={setNewCountPump} />

        </Box>
    )
}