import ExcelJS from "exceljs"
import { baseUrl, Constants } from "./Constants";

export function formatDate(dateString, firstYear = false, separator = '-') {
  const inputDate = dateString ? new Date(dateString) : new Date(); // Usar fecha actual si no se proporciona ninguna cadena

  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const date = String(inputDate.getUTCDate()).padStart(2, '0');

  if (firstYear) {
    return `${year}${separator}${month}${separator}${date}`;
  } else {
    return `${date}${separator}${month}${separator}${year}`;
  }
}


export const organizarDatosPorCategoria = (datos, categoria) => {
  const dataSortByDate = datos.sort((a, b) => new Date(b.fecha_actualizacion) - new Date(a.fecha_actualizacion));
  const datosOrganizados = dataSortByDate.reduce((obj, manto) => {
    let categoriaRegistro = manto[categoria]
    if (categoria === "fecha_actualizacion") {
      categoriaRegistro = formatDate(manto[categoria], true);
    }

    obj[categoriaRegistro] = obj[categoriaRegistro] || [];
    obj[categoriaRegistro].push(manto);
    return obj;
  }, {});

  return datosOrganizados;
};


export const organizarJerarquia = (lista) => {
  const resultado = {};

  lista.forEach((objeto) => {
    const { fecha_registro, institucion, servicio, modelo } = objeto;
    const fechaF = formatDate(fecha_registro);

    if (!resultado[fechaF]) {
      resultado[fechaF] = {};
    }

    if (!resultado[fechaF][institucion]) {
      resultado[fechaF][institucion] = {};
    }

    if (!resultado[fechaF][institucion][servicio]) {
      resultado[fechaF][institucion][servicio] = {};
    }

    if (!resultado[fechaF][institucion][servicio][modelo]) {
      resultado[fechaF][institucion][servicio][modelo] = [];
    }

    resultado[fechaF][institucion][servicio][modelo].push(objeto);
  });

  return resultado;
};


export const handleDownloadExcel = async (data, filename = `Inventario_${formatDate()}`) => {
  const dataReducida = data.map(seleccionarCampos)
  try {
    // Crea un nuevo libro de Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Definir las cabeceras
    const headers = Object.keys(dataReducida[0]);
    worksheet.addRow(headers);

    // Agregar datos
    dataReducida.forEach(obj => {
      const row = [];
      headers.forEach(header => {
        row.push(obj[header]);
      });
      worksheet.addRow(row);
    });

    // Guardar el libro de Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);

    console.log('Archivo Excel descargado satisfactoriamente.');
  } catch (err) {
    console.error('Error al descargar el archivo Excel:', err);
  }
};

const seleccionarCampos = (objeto) => {
  return {
    SERIE: objeto.serie,
    QR: objeto.qr,
    MODELO: objeto.modelo,
    TIPO: objeto.tipo,
    INSTITUCION: objeto.institucion,
    SERVICIO: objeto.servicio,
    FECHA: formatDate(objeto.fecha_actualizacion, true),
    RESPOSABLE: objeto.responsable,
    AGREGADO: objeto.agregado

  };
};

export const addServices = async (servicio) => {
  const dataToSend = {
    "servicio": servicio
  };

  console.log(dataToSend);

  try {
    const response = await fetch(setUrl("servicios"), {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(dataToSend)
    });

    const serverResponse = await response.json();
    const newServiceAdded = { id: serverResponse.insertId, servicio: dataToSend.servicio }
    return newServiceAdded

  } catch (err) {
    console.error(err); // Puedes configurar esto según tus necesidades
    throw err;
  }
};


export function sortObjectByArrayLength(obj) {
  // Convertir el objeto en un array de pares [clave, valor]
  const entries = Object.entries(obj);

  // Ordenar el array basado en la longitud del array de cada entrada
  entries.sort((a, b) => b[1].length - a[1].length);

  // Convertir el array de vuelta a un objeto
  return Object.fromEntries(entries);
}

export const sortDataBySerie = (data) => {
  return data.sort((a, b) => {
    const lengthA = a.serie.replace("C", "").length;
    const lengthB = b.serie.replace("C", "").length;
    return lengthA - lengthB;
  });
}



export const downloadRequiredFormat = async (userName) => {
  try {
    // Crea un nuevo libro de Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    // Definir las cabeceras
    const headers = Constants.validHeaders
    worksheet.addRow(headers);
    // Guardar el libro de Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${userName}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);

    console.log('Archivo Excel descargado satisfactoriamente.');
  } catch (err) {
    console.error('Error al descargar el archivo Excel:', err);
  }

}


export const fetchLastService = () => {
  const lastServiceJSON = localStorage.getItem("lastservice");

  if (lastServiceJSON) {
    try {
      return JSON.parse(lastServiceJSON);
    } catch (error) {
      console.error("Error parsing last service JSON:", error);
      return null;
    }
  } else {
    // Si no hay nada en el localStorage, devuelve null
    return null;
  }
};


export const setUrl = (extension) => {
  return baseUrl + extension
}