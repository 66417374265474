import { Box, Button, CircularProgress, Modal, TextField, Typography } from "@mui/material"
import { Colors } from "../utils/Colors";
import { useState } from "react";
import { CheckCircle, Error } from "@mui/icons-material";
import { setUrl } from "../utils/common";

export const EditPassword = ({ open, setOpenEdit, userId, oldPassword }) => {
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
    const [password, setPassword] = useState("")
    const [exito, setExito] = useState(false)
    const [myState, setMyState] = useState("edit")
    const [errorMessage, setErrorMessage] = useState("Upps! algo salio mal")
    const [noCoincide, setNoCoincide] = useState(false)

    const handleClose = (e) => {
        setMyState("edit")
        setOpenEdit(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        bgcolor: 'background.paper',
        border: `2px solid ${Colors.primary}`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '.5rem', // Esquinas redondeadas
        p: 3,
    };

    const handleEditData = (e) => {
        e.preventDefault();
        setMyState("loading")
        if (password !== oldPassword) {
            setMyState("result")
            setExito(false)
            setErrorMessage("Contraseña incorrecta")
        } else {
            handleChangePassword()
        }

    };

    const handleChangePassword = () => {
        const dataToSend = {
            password: newPassword
        };

        fetch(setUrl(`usuarios/${userId}`), {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(dataToSend)
        })
            .then((resp) => {
                if (resp.status === 200) {
                    setExito(true);
                } else {
                    setExito(false);
                }
                return resp.json();
            })
            .then((data) => {
                setMyState("result");
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
                setMyState("result");
                setExito(false);
            });
    }

    const handleConfirmPassword =(e) =>{
        const value = e.target.value
        setNewPasswordConfirm(value)
        if(value!==newPassword){
            setNoCoincide(true)
        }else{
            setNoCoincide(false)
        }

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    myState === "edit" && (
                        <Box
                            component="form"
                            onSubmit={handleEditData}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alingItem: "center",
                                gap: ".5rem",
                            }}
                        >
                            <TextField
                                size="small"
                                variant="outlined"
                                label="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                required
                            />
                            <TextField
                                size="small"
                                variant="outlined"
                                label="Nueva contraseña"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                type="password"
                                required

                            />
                            <TextField
                                size="small"
                                variant="outlined"
                                label="Confirmar nueva contraseña"
                                value={newPasswordConfirm}
                                onChange={handleConfirmPassword}
                                error={noCoincide}
                                helperText= {noCoincide ? "Las contraseñas no coinciden": ""}
                                type="password"
                                required

                            />
                            <Button
                                variant="contained"
                                type="submit"
                                disabled ={noCoincide}
                            >
                                Cambiar
                            </Button>
                        </Box>
                    )
                }
                {
                    myState === "loading" && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                gap: ".5rem",
                            }}
                        >
                            <CircularProgress />
                            <Typography>Procesando ...</Typography>
                        </Box>
                    )
                }
                {
                    myState === "result" && (
                        exito ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    gap: ".5rem",
                                }}
                            >
                                <CheckCircle fontSize="large" color="success" />
                                <Typography> Contraseña actualizada</Typography>
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                gap="1rem"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        gap: ".5rem",
                                    }}
                                >
                                    <Error fontSize="large" color="error" />
                                    <Typography> {errorMessage}</Typography>

                                </Box>
                                <Button variant="contained" onClick={(e) => setMyState("edit")}>
                                    Reintentar
                                </Button>
                            </Box>


                        )
                    )
                }


            </Box>

        </Modal>
    )
}