import { Box, CircularProgress, Container, IconButton, Paper, Typography } from "@mui/material";
import { Colors } from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { ArrowBack, Download, Logout, PersonAddAlt, Visibility, VisibilityOff } from "@mui/icons-material";
import { handleDownloadExcel, organizarDatosPorCategoria, setUrl, sortObjectByArrayLength } from "../../utils/common";
import { DetailsByUser } from "../../components/DetailsByUser";
import { AddUser } from "../../components/AddUser";

const AdminView = () => {

    const [inventoryByUser, setInventoryByUser] = useState({})
    const [inventarios, setInventarios] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [showAddUser, setShowAddUser] = useState(false)
    const [showHistory, setShowHistory] = useState(false)
    const [ countPump, setCountPump] = useState({})

    const navigate = useNavigate()
    const objetCurrentUser = localStorage.getItem("currentUser");
    const currentUser = useMemo(() => JSON.parse(objetCurrentUser) || null, [objetCurrentUser]);

    useEffect(() => {
        console.log("pidiendo datos")
        if (!currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate])


    useEffect(() => {
        Promise.all([
            fetch(setUrl("inventario/editados")).then((response) => response.json()),
            fetch(setUrl("usuarios")).then((response) => response.json())
        ])
            .then(([inventariosData, responsablesData]) => {


                // Crear un mapeo de IDs a nombres de usuario
                const userMap = responsablesData.reduce((acc, user) => {
                    acc[user.id] = user.usuario;
                    return acc;
                }, {});

                // Crear un mapeo de IDs a cantidad de bombas
                const countMap = responsablesData.reduce((acc, user) => {
                    acc[userMap[user.id]] = user.count_pump;
                    return acc;
                }, {});

                setCountPump(countMap)

                const dataWithUserName = inventariosData.map(inv => ({
                    ...inv,
                    responsable: userMap[inv.responsable_id] || "Unknown",
                }))

                const dataSortedByResp = organizarDatosPorCategoria(dataWithUserName, "responsable")

                const sortedData = sortObjectByArrayLength(dataSortedByResp)

                setInventarios(dataWithUserName)

                setInventoryByUser(sortedData)

                setLoaded(true)

            })
            .catch((error) => console.error(error));
    }, []);


    const getUsuario = (usuarioObject) => {
        let usuario
        if (usuarioObject) {
            usuario = usuarioObject.usuario.split(" ")[0]
        } else usuario = "Unknown"
        return usuario
    }
    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        navigate("/")
    }

    const handleGoToHome = () => {
        navigate(`/home/${currentUser.id}`)
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: "100%",
                minHeight: "100vh",
                padding: "0",
                background: Colors.primaryBack
            }}>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="0 1rem"
                sx={{
                    width: "100%",
                    background: Colors.primary,
                    position: "fixed",
                    top: 0,
                    right: 0,
                }}
            >
                <IconButton
                    title="Ir a home"
                    onClick={(e) => handleGoToHome()}
                    sx={{
                        color: "white"
                    }}
                >
                    <ArrowBack />
                </IconButton>
                <Typography sx={{ color: "white" }}>{getUsuario(currentUser)}</Typography>
                <Box>
                    <IconButton
                        title="Agregar usuario"
                        onClick={(e) => setShowAddUser(true)}
                        sx={{
                            color: "white"
                        }}
                    >
                        <PersonAddAlt />
                    </IconButton>
                    <IconButton
                        title="Cerrar Sesion"
                        onClick={(e) => handleLogout()}
                        sx={{
                            color: "white"
                        }}
                    >
                        <Logout />
                    </IconButton>
                </Box>

            </Box>
            <Container
                maxWidth="sm"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                    marginTop: "3rem",
                    marginBottom: "1rem",
                    gap: "1rem"
                }}
            >
                {
                    showAddUser && (
                        <AddUser setShowAddUser={setShowAddUser} />
                    )
                }
                <Paper elevation={3} sx={{ width: "100%" }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap=".5rem"
                        sx={{
                            width: "100%",
                            padding: ".5rem"
                        }}
                    >
                        {
                            !isLoaded ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding="2rem"
                                    sx={{ width: "100%" }}
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        display="flex"
                                        padding="0.2rem .5rem"
                                        sx={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="subtitle2"><strong>Total:</strong> {inventarios.length} </Typography>
                                        <Box>
                                            <IconButton
                                                title="Visualizar historial"
                                                onClick={(e) => setShowHistory(!showHistory)}
                                                sx={{
                                                    color: Colors.primary
                                                }}
                                            >
                                                {
                                                    showHistory ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )
                                                }
                                            </IconButton>
                                            <IconButton
                                                title="Descargar Excel"
                                                onClick={(e) => handleDownloadExcel(inventarios)}
                                                sx={{
                                                    color: Colors.primary
                                                }}
                                            >
                                                <Download />
                                            </IconButton>
                                        </Box>

                                    </Box>

                                    {
                                        showHistory && (
                                            Object.keys(inventoryByUser).map((invKey, index) => (
                                                <DetailsByUser key={index} inventarios={inventoryByUser[invKey]} responsable={invKey} countPump = {countPump[invKey]} />
                                            ))
                                        )
                                    }

                                </>

                            )
                        }
                    </Box>


                </Paper>
            </Container>

        </Box>
    )

}


export default AdminView;