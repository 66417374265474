import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { organizarDatosPorCategoria, } from '../../utils/common';
import { MyItemN1 } from './MyItemN1';
import CategorySelector from './CategorySelector';
import { Progreso } from '../Avance';


const ListView = ({ user, data, handleAutoSearch }) => {

    const [categoria, setCategoria] = useState("fecha_actualizacion")

    const [viewAll, setViewAll] = useState(false)
    const datosOrganizados = organizarDatosPorCategoria(data, categoria)

    let avance = 100
    if (user.count_pump > 0) {
        avance = Math.round(100 * data.length / user.count_pump)
    }


    let datosLimitados

    if (!viewAll) {
        datosLimitados = Object.fromEntries(Object.entries(datosOrganizados).slice(0, 1));
    } else {
        datosLimitados = datosOrganizados
    }

    return (

        <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            sx={{
                width: "100%",
                alignItems: 'center',
                paddingTop: "1rem",
                paddingBottom: "1rem"
            }}>
            <CategorySelector setCategoria={setCategoria} />

            {
                user.count_pump !== 0 && (
                    <Box
                        display="flex"
                        gap="1rem"
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 1rem"
                        }}
                    >
                        <Progreso avance={avance} />
                        <Typography variant='subtitle2'><strong>({user.count_pump})</strong></Typography>

                    </Box>
                )
            }
            <Box
                display="flex"
                padding="0 1rem"
                sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <Typography variant='subtitle2'><strong>Avance:</strong> {data.length} </Typography>
                {
                    Object.entries(datosOrganizados).length > 1 && (
                        <Button
                            variant='contained'
                            onClick={(e) => setViewAll(!viewAll)}
                        >
                            {
                                !viewAll ? (
                                    <b>Ver todo (+{Object.entries(datosOrganizados).length - 1})</b>
                                ) : (
                                    <b>Ver menos</b>
                                )
                            }
                        </Button>
                    )
                }
            </Box>


            {

                Object.keys(datosLimitados).map((key, index) => (
                    <MyItemN1 key={index} fecha={key} inventarios={datosLimitados[key]} handleAutoSearch={handleAutoSearch} user={user} />
                ))

            }



        </Box>
    )
}

export default ListView;