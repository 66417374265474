import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const CategorySelector = ({ setCategoria }) => {
    const [selectedValue, setSelectedValue] = useState('fecha_actualizacion');

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
        setCategoria(event.target.value)
    };

    const radios = [
        { id: 'fecha_actualizacion', label: 'Fecha' },
        { id: 'institucion', label: 'Institución' },
        { id: 'servicio', label: 'Servicio' }

    ];

    return (
        <FormControl component="fieldset">
            <RadioGroup row defaultValue="fecha_actualizacion">
                {radios.map((radio) => (
                    <FormControlLabel
                        key={radio.id}
                        labelPlacement="top"
                        control={
                            <Radio
                                checked={selectedValue === radio.id}
                                onChange={handleRadioChange}
                                value={radio.id}
                                name="radio-button"
                            />
                        }
                        label={radio.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default CategorySelector;