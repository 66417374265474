import { Box, Button, CircularProgress, Modal, TextField, Typography } from "@mui/material"
import { Colors } from "../utils/Colors";
import { useState } from "react";
import { CheckCircle, Error } from "@mui/icons-material";
import { setUrl } from "../utils/common";

export const EditCountPump = ({ open, setOpenEdit, userId, newCountPump, setNewCountPump }) => {
    const [value, setValue] = useState(newCountPump)
    const [exito, setExito] = useState(false)
    const [myState, setMyState] = useState("edit")

    const handleClose = (e) => {
        setMyState("edit")
        setOpenEdit(false)
    }

    const onChangeValue = (e) => {
        setValue(parseInt(e.target.value));
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "350px",
        bgcolor: 'background.paper',
        border: `2px solid ${Colors.primary}`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '.5rem', // Esquinas redondeadas
        p: 3,
    };

    const handleEditData = (e) => {
        e.preventDefault();
        setMyState("loading")

        const dataToSend = {
            count_pump: value
        };

        fetch(setUrl(`usuarios/${userId}`), {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(dataToSend)
        })
            .then((resp) => {
                if (resp.status === 200) {
                    setExito(true);
                    setNewCountPump(value)
                } else {
                    setExito(false);
                }
                return resp.json();
            })
            .then((data) => {
                setMyState("result");
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
                setMyState("result");
                setExito(false);
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {
                    myState === "edit" && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alingItem: "center",
                                gap: ".5rem",
                            }}
                        >
                            <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                label="Cantidad de Bombas"
                                value={value}
                                onChange={onChangeValue}
                            />
                            <Button
                                disabled={value === newCountPump}
                                variant="contained"
                                onClick={handleEditData}
                            >
                                Guardar
                            </Button>
                        </Box>
                    )
                }
                {
                    myState === "loading" && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                gap: ".5rem",
                            }}
                        >
                            <CircularProgress />
                            <Typography>Procesando ...</Typography>
                        </Box>
                    )
                }
                {
                    myState === "result" && (
                        exito ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    gap: ".5rem",
                                }}
                            >
                                <CheckCircle fontSize="large" color="success" />
                                <Typography> Actualizado con exito</Typography>
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                gap="1rem"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        gap: ".5rem",
                                    }}
                                >
                                    <Error fontSize="large" color="error" />
                                    <Typography> Upps! algo salio mal</Typography>

                                </Box>
                                <Button variant="contained" onClick={(e) => setMyState("edit")}>
                                    Reintentar
                                </Button>
                            </Box>


                        )
                    )
                }


            </Box>

        </Modal>
    )
}