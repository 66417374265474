import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import MyInputV2 from "./MyInputV2";
import {
  addServices,
  fetchLastService,
  formatDate,
  setUrl,
} from "../utils/common";
import Exito from "./Exito";
import Error from "./Error";
import { ArrowBackRounded } from "@mui/icons-material";

const MyForm = ({
  data,
  instituteOptions,
  serviceOptions,
  setSearched,
  setShowForm1,
  handleSubmit,
  obtenerInventarios,
  setServiceOptions,
}) => {
  const [exito, setExito] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [newService, setNewService] = useState("");
  const [showErrorHelper, setShowErrorHelper] = useState(false);
  const objetCurrentUser = localStorage.getItem("currentUser");
  const currentUser = useMemo(
    () => JSON.parse(objetCurrentUser) || {},
    [objetCurrentUser],
  );
  const optionsList = serviceOptions.map((sop) => sop.servicio);
  const [isAutocompleted, setIsAutocompleted] = useState(false);
  const [lastService, setLastService] = useState(null);

  //Datos para el autocompletado de tipos y modelos
  const tipos = useMemo(
    () => [
      { id: 1, tipo: "INFUSOR" },
      { id: 2, tipo: "PERFUSOR" },
      { id: 3, tipo: "FUENTE US" },
      { id: 4, tipo: "FUENTE EU" },
      { id: 5, tipo: "NO APLICA" },
    ],
    [],
  );

  const modelos = useMemo(
    () => [
      { id: 1, modelo: "SPACE" },
      { id: 2, modelo: "SPACE PLUS" },
      { id: 3, modelo: "COMPACT" },
      { id: 4, modelo: "COMPACT PLUS" },
      { id: 5, modelo: "ENTEROPORT PLUS" },
      { id: 6, modelo: "FMS" },
      { id: 7, modelo: "FM" },
    ],
    [],
  );

  const esModeloValido = modelos.some(
    (modelo) => modelo.modelo === data.modelo,
  ); //verificamo que sea una bomba

  const [formData, setFormData] = useState({
    qr: "",
    institucion: null,
    servicio: null,
  });

  //Obteniendo el ultimo servicio registrado
  useEffect(() => {
    const lastServiceObject = fetchLastService();
    setLastService(lastServiceObject);
  }, []);

  //Inicializando el formulario
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      institucion:
        instituteOptions.find(
          (inst) => inst.institucion === data.institucion,
        ) || null,
      servicio: data.servicio
        ? serviceOptions.find((serv) => serv.servicio === data.servicio)
        : lastService,
      qr: data.qr ? data.qr : "",
    }));

    if (data.servicio) {
      setIsAutocompleted(false);
    } else {
      lastService ? setIsAutocompleted(true) : setIsAutocompleted(false);
    }
  }, [data, instituteOptions, serviceOptions, modelos, tipos, lastService]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await fetch(setUrl(`inventario/qr/${formData.qr}`));
    const inventarioByQr = await response.json();

    if (inventarioByQr.length > 0) {
      if (inventarioByQr[0].serie === data.serie) {
        handleSubmitv2();
      } else {
        setShowErrorHelper(true);
        setLoading(false);
      }
    } else {
      handleSubmitv2();
    }
  };

  const handleSubmitv2 = async () => {
    // Aquí puedes enviar los datos al servidor o realizar otras acciones
    const dataToSend = {
      id: data.id,
      serie: data.serie,
      qr: formData.qr,
      modelo: data.modelo,
      tipo: data.tipo,
      institucion: formData.institucion,
      servicio: newService.trim(),
      fecha_actualizacion: formatDate(undefined, true),
      responsable_id: currentUser.id,
    };

    try {
      const response = await fetch(setUrl(`inventario/${data.id}`), {
        method: "PATCH",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      const responseData = await response.json();

      console.log(responseData);

      if (response.status === 200) {
        setExito(true);
      } else {
        setExito(false);
      }

      setLoading(false);
      setShowForm(false);

      // Aquí usamos await dentro del bloque async para asegurarnos de que se maneje correctamente
      let newServiceAdded = formData.servicio;

      if (!optionsList.includes(newService.trim())) {
        newServiceAdded = await addServices(newService.trim());
        setServiceOptions((prevState) => [...prevState, newServiceAdded]);
        console.log("Agregando nuevo servicio");
      }

      localStorage.setItem("lastservice", JSON.stringify(newServiceAdded));
    } catch (err) {
      console.error(err);
      setLoading(false);
      setExito(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        padding: "1rem",
      }}
    >
      {showForm ? (
        <>
          <Box
            display="flex"
            gap="1rem"
            justifyContent="center"
            alignItems="center"
            paddingBottom="1rem"
          >
            <IconButton
              title="Cancelar edicion"
              color="primary"
              aria-label="arrow-back"
              onClick={(e) => setShowForm1(false)}
            >
              <ArrowBackRounded />
            </IconButton>

            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Editando {data.serie}
            </Typography>
          </Box>
          <form
            onSubmit={handleUpdate}
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {esModeloValido && (
              <MyInputV2
                error={showErrorHelper}
                helperText="El Qr ya esta en uso"
                label={"Qr"}
                value={formData.qr}
                setValue={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    qr: "",
                  }))
                }
                onChange={(event) => {
                  const newValue = event.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    qr: newValue ? newValue : "",
                  }));
                }}
              />
            )}

            <Autocomplete
              id="institucion"
              size="small"
              options={instituteOptions}
              getOptionLabel={(option) => option.institucion}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={formData.institucion}
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  institucion: newValue ? newValue : null,
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Institucion" fullWidth required />
              )}
            />

            <Autocomplete
              freeSolo
              id="servicios"
              size="small"
              options={serviceOptions}
              getOptionLabel={(option) => option.servicio || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={formData.servicio}
              onInputChange={(_, newValue) => {
                if (lastService !== null) {
                  if (newValue !== lastService.servicio) {
                    setIsAutocompleted(false);
                  }
                }
                setNewService(newValue);
              }}
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  servicio: newValue ? newValue : null,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Servicio"
                  error={isAutocompleted}
                  helperText={
                    isAutocompleted
                      ? "Autocompletado del ultimo registro, cambiar si es necesario"
                      : ""
                  }
                  fullWidth
                  required
                />
              )}
            />

            {/* <TextField size="small" type="date" value={date} label="Fecha" onChange={handleChange} required /> */}

            <Button
              type="submit"
              variant="contained"
              sx={{ fontWeight: "bold" }}
            >
              Editar
            </Button>

            {loading && (
              <Modal
                open={loading}
                onClose={() => setLoading(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  display="flex"
                  sx={{
                    height: "100vh",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, color: "white" }}
                  >
                    <strong>Editando Informacion</strong>
                  </Typography>
                </Box>
              </Modal>
            )}
          </form>
        </>
      ) : exito ? (
        <Exito
          setSearched={setSearched}
          handleSubmit={handleSubmit}
          obtenerInventarios={obtenerInventarios}
          currentUser={currentUser}
        />
      ) : (
        <Error setShowForm={setShowForm} />
      )}
    </Box>
  );
};

export default MyForm;
