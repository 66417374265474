import { Box, Typography } from "@mui/material"
import { Colors } from "../utils/Colors"

export const Progreso = ({ avance }) => {
    const roundAvance = Math.round(avance)
    return (
        <Box
            display="flex"
            gap=".3rem"
            sx={{
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center"
            }}
        >
            <Box
                display="flex"
                width="100%"
                height=".8rem"
                borderRadius=".3rem"
                boxSizing="border-box"
                overflow="hidden"
            >
                <Box
                    sx={{ flex: `0 0 ${roundAvance}%`, backgroundColor: Colors.primary }}
                >
                </Box>
                <Box
                    sx={{ flex: 1, backgroundColor: 'lightcoral' }}
                >
                </Box>
            </Box>
            <Typography variant="subtitle2"><strong>{roundAvance}%</strong></Typography>
        </Box>
    )
}