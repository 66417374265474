import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, Modal, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { CheckCircle, Close, Error } from "@mui/icons-material"
import { setUrl } from "../utils/common"

export const AddUser = ({ setShowAddUser }) => {

    const [name, setName] = useState("")
    const [countPump, setCountPum] = useState(0)
    const [surname, setSurname] = useState("")
    const [isAdmin, setIsAdmin] = useState(false)
    const [loading, setLoading] = useState(false)
    const [exito, setExito] = useState(false)
    const [showForm, setShowForm] = useState(true)
    const [message, setMessage] = useState("")


    const sendData = (dataToSend) => {
        fetch(setUrl("usuarios"), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(dataToSend)
        })
            .then(resp => {
                if (resp.status === 200) {
                    setExito(true);
                } else {
                    setExito(false);
                }
                return resp.json()
            })
            .then(data => {
                setLoading(false);
                setShowForm(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setExito(false); // Puedes configurar esto según tus necesidades
            });
    }

    const handleAddData = async (e) => {
        e.preventDefault();
        setLoading(true);
        const pass = surname.trim().slice(0, 4) + name.slice(0, 2) + "pe"
        const usern = name.trim().split(" ")[0]
        const dataToSend = {
            username: usern.toLowerCase(),
            password: pass.toLowerCase(),
            usuario: name + " " + surname,
            isadmin: isAdmin,
            count_pump: countPump
        };



        try {
            const response = await fetch(setUrl(`usuarios/usuario/${dataToSend.password}`));
            const responseJson = await response.json();
            const objeto = responseJson[0]
            const valor = objeto["COUNT(*)"]
            console.log(parseInt(valor) > 0)

            if (parseInt(valor) > 0) {
                setLoading(false)
                setShowForm(false);
                setMessage("El usuario ya existe")
                setExito(false)

            } else {
                sendData(dataToSend)
            }


        } catch (error) {
            console.error("Error al obtener los mantenimientos:", error);
            setLoading(false)
            setExito(false)
        }
    };


    const handleSetAdmin = (e) => {
        setIsAdmin(e.target.checked)
    }

    return (
        showForm ? (
            <Paper
                component="form"
                onSubmit={handleAddData}
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: ".5rem",
                    gap: ".5rem"
                }}>
                <TextField
                    size="small"
                    value={name}
                    label="Nombres"
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                        endAdornment: name && (
                            <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    setName("")
                                }}>
                                    <Close color="primary" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required />

                <TextField
                    size="small"
                    value={surname}
                    label="Apellidos"
                    onChange={(e) => setSurname(e.target.value)}
                    InputProps={{
                        endAdornment: surname && (
                            <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    setSurname('')
                                }}>
                                    <Close color="primary" />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required />

                <TextField
                    size="small"
                    type="number"
                    value={countPump}
                    label="Cantidad de Bombas"
                    onChange={(e) => setCountPum(e.target.value)}
                    required />

                <FormControlLabel
                    label="Marcar como usuario administrador"
                    control={<Checkbox checked={isAdmin} onChange={handleSetAdmin} />}
                />

                <Box
                    display="flex"
                    gap="1rem"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        color="warning"
                        type="submit"
                        variant="contained"
                        sx={{
                            flexBasis: "45%"
                        }}
                    >
                        AGREGAR
                    </Button>
                    <Button
                        color="info"
                        variant="contained"
                        sx={{
                            flexBasis: "45%"
                        }}
                        onClick={e => setShowAddUser(false)}
                    >
                        CANCELAR
                    </Button>
                </Box>

                {loading && (
                    <Modal
                        open={loading}
                        onClose={() => setLoading(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box display="flex" sx={{ height: "100vh", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                            <Typography id="modal-modal-description" sx={{ mt: 2, color: "white" }}>
                                <strong>Editando Informacion</strong>
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Paper>
        ) : (
            <Paper elevation={3} sx={{ padding: "1rem", width: "100%" }}>
                {
                    exito ? (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h6" gutterBottom>
                                Usuario registrado con éxito
                            </Typography>
                            <CheckCircle color="primary" style={{ fontSize: 48 }} />
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={(e) => setShowAddUser(false)}>
                                    Aceptar
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h6" gutterBottom>
                                {message}
                            </Typography>
                            <Error color="error" style={{ fontSize: 48 }} />
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={(e) => setShowForm(true)}>
                                    Aceptar
                                </Button>
                            </Box>
                        </Box>
                    )
                }
            </Paper>

        )
    )
}