import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledChip = styled(Chip)(({ theme }) => ({
    marginBottom: theme.spacing(1), // Ajustsupfa el espacio entre las filas
    marginRight: theme.spacing(1), // Ajusta el espacio entre los chips en la misma fila
}));

export function ListChips({ seriesUploadFailed, irArriba, setSerie }) {
    const handleClick = (label) => {
        setSerie(label)
        irArriba()
    };
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: "flex-start",
                flexWrap: 'wrap'
            }}
        >
            {seriesUploadFailed.map((supf, index) => (
                <StyledChip
                    key={index}
                    label={supf}
                    variant="outlined"
                    onClick={e => handleClick(supf)}
                />
            ))}
        </Box>
    );
}
