import { Home } from './pages/home/Home';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login/Login';
import AdminView from './pages/admin/AdminVIew';

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Login/>} />
        <Route path='/home/:userId' element={<Home />} />
        <Route path='/admin' element={<AdminView />} />
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  );
}

export default App;
